// src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Navbar from './components/layout/Navbar';
import HomePage from './components/pages/HomePage';
import LoginPage from './components/pages/LoginPage';
import SignupPage from './components/pages/SignupPage';
import ChatPage from './components/pages/ChatPage';
import GoogleAuthSuccess from './components/pages/GoogleAuthSuccess';
import PrivateRoute from './components/common/PrivateRoute';
import SuccessPage from './components/pages/SuccessPage';
import SubscriptionManagementPage from './components/pages/SubscriptionManagementPage';
import PaymentHistoryPage from './components/pages/PaymentHistoryPage';

function App() {
  const LMARHALA = process.env.REACT_APP_LMARHALA;

  const getChatRoute = () => {
    if (LMARHALA === '0') {
      return <Route path="/chat" element={<ChatPage />} />;
    } else {
      return (
        <Route
          path="/chat"
          element={
            <PrivateRoute>
              <ChatPage />
            </PrivateRoute>
          }
        />
      );
    }
  };

  // Only show /login & /signup when LMARHALA != '0'
  const getAuthRoutes = () => {
    if (LMARHALA !== '0') {
      return (
        <>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
        </>
      );
    }
    return null;
  };

  // Subscription routes only if LMARHALA === '2'
  const getSubscriptionRoute = () => {
    if (LMARHALA === '2') {
      return (
        <Route
          path="/subscription"
          element={
            <PrivateRoute>
              <SubscriptionManagementPage />
            </PrivateRoute>
          }
        />
      );
    }
    return null;
  };

  // Payment history routes only if LMARHALA === '2'
  const getPaymentHistoryRoute = () => {
    if (LMARHALA === '2') {
      return (
        <Route
          path="/payment-history"
          element={
            <PrivateRoute>
              <PaymentHistoryPage />
            </PrivateRoute>
          }
        />
      );
    }
    return null;
  };

  // Success route only for LMARHALA === '2'
  const getSuccessRoute = () => {
    if (LMARHALA === '2') {
      return (
        <Route
          path="/success"
          element={
            <PrivateRoute>
              <SuccessPage />
            </PrivateRoute>
          }
        />
      );
    }
    return null;
  };

  return (
    <AuthProvider>
      <Router>
        <NavbarWrapper />
        <Routes>
          <Route path="/" element={<HomePage />} />
          
          {/* Conditionally render auth routes */}
          {getAuthRoutes()}

          <Route path="/auth/google/success" element={<GoogleAuthSuccess />} />

          {/* Chat route (public if LMARHALA=0, otherwise private) */}
          {getChatRoute()}

          {/* Conditionally render success route for LMARHALA=2 */}
          {getSuccessRoute()}

          {/* Conditionally render subscription and payment routes for LMARHALA=2 */}
          {getSubscriptionRoute()}
          {getPaymentHistoryRoute()}
        </Routes>
      </Router>
    </AuthProvider>
  );
}

function NavbarWrapper() {
  const location = useLocation();
  const hideNavbarPaths = ['/chat'];
  const shouldHideNavbar = hideNavbarPaths.includes(location.pathname);
  return shouldHideNavbar ? null : <Navbar />;
}

export default App;
