// src/components/pages/SubscriptionManagementPage.js

import React, { useEffect, useState } from 'react';
import api from '../../services/apiService';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

const SubscriptionManagementPage = () => {
  const [subscription, setSubscription] = useState(null);
  const [hasRecorded, setHasRecorded] = useState(false);
  const [loading, setLoading] = useState(true); // New state to manage loading
  const [error, setError] = useState(null); // New state to manage errors

  const navigate = useNavigate();

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const response = await api.get('/subscription');
        setSubscription(response.data.subscription);
      } catch (err) {
        if (err.response && err.response.status === 404) {
          // If backend still returns 404, treat as no subscription
          setSubscription(null);
        } else {
          console.error('Error fetching subscription:', err);
          setError('Failed to load subscription data.');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchSubscription();
  }, []);

  useEffect(() => {
    const recordVisit = async () => {
      try {
        if (!hasRecorded) {
          await api.post('/subscription/record-subscription-page-visit');
          setHasRecorded(true);
        }
      } catch (err) {
        console.error('Error recording subscription page visit:', err);
      }
    };

    recordVisit();
  }, [hasRecorded]);

  const handleCancelSubscription = async () => {
    if (window.confirm('Are you sure you want to cancel your subscription?')) {
      try {
        await api.post('/subscription/cancel');
        alert('Your subscription has been scheduled for cancellation.');
        // Refresh the subscription status
        const updatedSubscription = await api.get('/subscription');
        setSubscription(updatedSubscription.data.subscription);
      } catch (err) {
        console.error('Error cancelling subscription:', err);
        alert('Failed to cancel subscription. Please try again.');
      }
    }
  };

  const handleSubscribe = async () => {
    try {
      // Call backend to create the Checkout Session
      const response = await api.post('/payment/create-checkout-session');
      const { sessionId } = response.data;

      const stripe = await stripePromise;
      // Redirect to Stripe Checkout
      await stripe.redirectToCheckout({ sessionId });
    } catch (err) {
      console.error('Error during subscription:', err);
      alert('Failed to initiate subscription process. Please try again.');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // If subscription is null, user has no subscription
  if (!subscription) {
    return (
      <div>
        <h1>Upgrade Your Account</h1>
        <button onClick={handleSubscribe}>Subscribe Now</button>
      </div>
    );
  }

  const { status, cancelAtPeriodEnd, endDate } = subscription;

  return (
    <div>
      <h1>Manage Your Subscription</h1>
      {status === 'active' && cancelAtPeriodEnd && endDate ? (
        <div>
          <p>
            Your subscription is scheduled to be canceled on{' '}
            {new Date(endDate).toLocaleDateString()}.
          </p>
          <p>You can continue to use our services until then.</p>
        </div>
      ) : status === 'active' ? (
        <div>
          <p>Status: Active</p>
          <button onClick={handleCancelSubscription}>Cancel Subscription</button>
        </div>
      ) : status === 'canceled' ? (
        <div>
          <h1>Upgrade Your Account</h1>
          <button onClick={handleSubscribe}>Subscribe Now</button>
        </div>
      ) : (
        <div>
          <p>Status: {status}</p>
          {status !== 'canceled' && (
            <button onClick={handleCancelSubscription}>Cancel Subscription</button>
          )}
        </div>
      )}
    </div>
  );
};

export default SubscriptionManagementPage;
