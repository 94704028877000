// src/services/apiService.js
import axios from 'axios';

// const API_URL = 'http://localhost:4000/api/v1';
const API_URL = 'https://2a3a-160-178-172-112.ngrok-free.app/api/v1';

const api = axios.create({
  baseURL: API_URL,
});

export default api;
