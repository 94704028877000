// src/components/pages/GoogleAuthSuccess.js
import React, { useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import api from '../../services/apiService';
import {jwtDecode} from 'jwt-decode'; // Corrected import

const GoogleAuthSuccess = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const { setUser } = useContext(AuthContext); // Ensure setUser is available

  useEffect(() => {
    const query = new URLSearchParams(search);
    const token = query.get('token');

    if (token) {
      localStorage.setItem('token', token);
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const decoded = jwtDecode(token);
      setUser(decoded);
      navigate('/chat');
    } else {
      navigate('/login');
    }
  }, [search, navigate, setUser]);

  return <div>Logging in...</div>;
};

export default GoogleAuthSuccess;
