// src/components/layout/Navbar.js

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import LogoutButton from '../common/LogoutButton';
import { AuthContext } from '../../contexts/AuthContext';

const Navbar = () => {
  const { user } = useContext(AuthContext);
  const LMARHALA = process.env.REACT_APP_LMARHALA;

  return (
    <nav>
      <ul>
        {/* Always show Home link */}
        <li>
          <Link to="/">Home</Link>
        </li>

        {/* LMARHALA === '0' => No login/signup, Chat is public */}
        {LMARHALA === '0' && (
          <>
            <li>
              <Link to="/chat">Chat</Link>
            </li>
            {/* If there's no reason to show logout for '0', remove it. 
                But if user can optionally log in, keep it. Adjust as needed. */}
            {user && (
              <li>
                <LogoutButton />
              </li>
            )}
          </>
        )}

        {/* LMARHALA === '1' => Must log in to access chat, but no subscription. */}
        {LMARHALA === '1' && (
          <>
            {user ? (
              <>
                <li>
                  <Link to="/chat">Chat</Link>
                </li>
                <li>
                  <LogoutButton />
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link to="/login">Login</Link>
                </li>
                <li>
                  <Link to="/signup">Sign Up</Link>
                </li>
              </>
            )}
          </>
        )}

        {/* LMARHALA === '2' => Must log in, subscription & payment available */}
        {LMARHALA === '2' && (
          <>
            {user ? (
              <>
                <li>
                  <Link to="/chat">Chat</Link>
                </li>
                <li>
                  <Link to="/subscription">Subscription</Link>
                </li>
                <li>
                  <Link to="/payment-history">Payment History</Link>
                </li>
                <li>
                  <LogoutButton />
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link to="/login">Login</Link>
                </li>
                <li>
                  <Link to="/signup">Sign Up</Link>
                </li>
              </>
            )}
          </>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
