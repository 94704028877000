// src/components/Modals/ReviewModal.js

import React, { useState, useContext, useEffect, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, Alert, Spinner, Row, Col } from 'react-bootstrap';
import { AuthContext } from '../../contexts/AuthContext';
import axios from 'axios';
import ReactStars from 'react-rating-stars-component';
import api from '../../services/apiService';

// Define initial state for the form using useReducer
const initialState = {
  name: '',
  stars: 0,
  text: '',
  errors: {},
};

// Reducer function to manage form state
const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_FIELD':
      return { ...state, [action.field]: action.value || '' };
    case 'SET_ERRORS':
      return { ...state, errors: action.errors };
    case 'RESET':
      return initialState;
    case 'SET_INITIAL_NAME':
      return { ...state, name: action.name || '' };
    default:
      return state;
  }
};

const ReviewModal = ({ show, handleClose, onReviewSubmitted, displayinputname }) => {
  const { user, token } = useContext(AuthContext);

  const [state, dispatch] = useReducer(reducer, initialState);
  const { name, stars, text, errors } = state;

  // Feedback state
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Character limits
  const TEXT_MIN_LENGTH = 1;
  const TEXT_MAX_LENGTH = 500;

  // Effect to set the initial name if user is authenticated and displayinputname is true
  useEffect(() => {
    if (displayinputname) {
      if (user && user.name) {
        dispatch({ type: 'SET_INITIAL_NAME', name: user.name });
      } else {
        dispatch({ type: 'SET_INITIAL_NAME', name: '' });
      }
    } else {
      dispatch({ type: 'SET_INITIAL_NAME', name: '' });
    }
  }, [user, displayinputname]);

  // Handle star rating change
  const handleStarsChange = useCallback((newStars) => {
    dispatch({ type: 'SET_FIELD', field: 'stars', value: newStars });
  }, []);

  // Validate form fields
  const validate = () => {
    const errors = {};

    // Only stars are required
    if (stars === 0) {
      errors.stars = 'Please select a star rating.';
    }

    // Optional fields: name and text
    if (displayinputname && name.trim().length === 0) {
      errors.name = 'Please enter your name.';
    }

    if (text && text.trim().length < TEXT_MIN_LENGTH) {
      errors.text = `Review must be at least ${TEXT_MIN_LENGTH} characters.`;
    } else if (text && text.trim().length > TEXT_MAX_LENGTH) {
      errors.text = `Review cannot exceed ${TEXT_MAX_LENGTH} characters.`;
    }

    dispatch({ type: 'SET_ERRORS', errors });

    // Return true if no errors
    return Object.keys(errors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset feedback messages
    setErrorMessage('');
    setSuccessMessage('');

    if (!validate()) {
      return;
    }

    // Prepare review data
    const reviewData = {
      stars,
      text: text.trim(),
    };

    // Include name if displayinputname is true
    if (displayinputname) {
      reviewData.name = name.trim();
    }

    setIsSubmitting(true);

    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      // Include Authorization header if user is authenticated
      if (user && token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }

      // Replace with your backend endpoint
      const response = await api.post('/reviews', reviewData, config);

      if (response.status === 201 || response.status === 200) {
        setSuccessMessage('Your review has been submitted successfully.');
        dispatch({ type: 'RESET' });
        if (onReviewSubmitted) {
          onReviewSubmitted(response.data.data);
        }
      } else {
        setErrorMessage('Unexpected response from the server. Please try again.');
      }
    } catch (err) {
      console.error('Error submitting review:', err);
      if (err.response && err.response.data && err.response.data.error) {
        setErrorMessage(err.response.data.error);
      } else {
        setErrorMessage('There was an error submitting your review. Please try again.');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handle modal close: reset feedback and form
  const onClose = () => {
    setErrorMessage('');
    setSuccessMessage('');
    dispatch({ type: 'RESET' });
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      aria-labelledby="review-modal-title"
      backdrop="static"
      keyboard={!isSubmitting}
    >
      <Modal.Header closeButton={!isSubmitting}>
        <Modal.Title id="review-modal-title">Write a Review</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit} noValidate>
        <Modal.Body>
          {/* Feedback Messages */}
          {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
          {successMessage && <Alert variant="success">{successMessage}</Alert>}

          {/* Conditionally Render Name Input */}
          {displayinputname && (
            <Form.Group controlId="formName">
              <Form.Label>Name {displayinputname && <span className="text-danger">*</span>}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={(e) =>
                  dispatch({ type: 'SET_FIELD', field: 'name', value: e.target.value || '' })
                }
                isInvalid={!!errors.name}
                aria-required={displayinputname ? "true" : "false"}
                aria-invalid={!!errors.name}
              />
              {user && user.name && (
                <Form.Text className="text-muted">
                  Your name has been pre-filled based on your account. You can edit it if you wish.
                </Form.Text>
              )}
              <Form.Control.Feedback type="invalid">
                {errors.name}
              </Form.Control.Feedback>
            </Form.Group>
          )}

          {/* Star Rating */}
          <Form.Group controlId="formStars" className="mt-3">
            <Form.Label>
              Rating <span className="text-danger">*</span>
            </Form.Label>
            <div>
              <ReactStars
                count={5}
                onChange={handleStarsChange}
                size={40}
                activeColor="#ffc107"
                value={stars}
                isHalf={false}
                name="stars"
                aria-label="Star Rating"
              />
            </div>
            {errors.stars && (
              <div className="text-danger mt-1">
                {errors.stars}
              </div>
            )}
            {stars > 0 && (
              <Form.Text className="text-muted">
                You rated this product {stars} out of 5 stars.
              </Form.Text>
            )}
          </Form.Group>

          {/* Review Text */}
          <Form.Group controlId="formText" className="mt-3">
            <Form.Label>Review</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Write your review here..."
              value={text}
              onChange={(e) =>
                dispatch({ type: 'SET_FIELD', field: 'text', value: e.target.value || '' })
              }
              isInvalid={!!errors.text}
              aria-required="false"
              aria-invalid={!!errors.text}
              maxLength={TEXT_MAX_LENGTH}
            />
            <Row className="justify-content-between">
              <Col xs="auto">
                <Form.Control.Feedback type="invalid">
                  {errors.text}
                </Form.Control.Feedback>
              </Col>
              <Col xs="auto" className="text-muted">
                {text.length}/{TEXT_MAX_LENGTH} characters
              </Col>
            </Row>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose} disabled={isSubmitting}>
            Close
          </Button>
          <Button
            variant="primary"
            type="submit"
            disabled={
              isSubmitting ||
              stars === 0 ||
              Object.keys(errors).length > 0
            }
          >
            {isSubmitting ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{' '}
                Submitting...
              </>
            ) : (
              'Submit Review'
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

ReviewModal.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onReviewSubmitted: PropTypes.func, // Optional callback after successful submission
  displayinputname: PropTypes.bool,   // Controls the visibility of the Name input
};

ReviewModal.defaultProps = {
  onReviewSubmitted: null,
  displayinputname: true, // Default to displaying the Name input
};

export default ReviewModal;
