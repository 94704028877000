// src/components/pages/HomePage.js
import { Link } from 'react-router-dom';
import './HomePage.css'; // Optional: For styling
import React, { useState, useRef, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext'; // Import AuthContext
import { useNavigate, useLocation } from 'react-router-dom'; // Added useLocation
import api from '../../services/apiService';
function HomePage() {

    const location = useLocation(); // Initialized useLocation

  // Access the current user from AuthContext
  var { user } = useContext(AuthContext);

  // If not authenticated, assign a fallback user ID
  if (user === null) {
    user = { id: "777777777", iat: 1733253711 };
  }


    useEffect(() => {
        // Function to send source to backend
        const sendSourceToBackend = async (source) => {
          try {
            await api.post('/visit', { source:JSON.stringify(source), userId: user.id });
            console.log('Source sent to backend:', source);
          } catch (error) {
            console.error('Error sending source to backend:', error);
          }
        };
    
        // Function to extract UTM parameters
        const getUTMParams = () => {
          const params = new URLSearchParams(location.search);
          const utm_source = params.get('utm_source');
          const utm_medium = params.get('utm_medium');
          const utm_campaign = params.get('utm_campaign');
          // Add other UTM parameters if needed
          return { utm_source, utm_medium, utm_campaign };
        };
    
        // Function to determine source
        const determineSource = () => {
          const { utm_source, utm_medium, utm_campaign } = getUTMParams();
          if (utm_source) {
            return { utm_source, utm_medium, utm_campaign };
          } else if (document.referrer) {
            try {
              const referrerUrl = new URL(document.referrer);
              return {
                URL: referrerUrl.toString(),
                hash: referrerUrl.hash,
                host: referrerUrl.host,
                hostname: referrerUrl.hostname,
                href: referrerUrl.href,
                origin: referrerUrl.origin,
                password: referrerUrl.password,
                pathname: referrerUrl.pathname,
                port: referrerUrl.port,
                protocol: referrerUrl.protocol,
                search: referrerUrl.search,
                searchParams: Object.fromEntries(referrerUrl.searchParams.entries()),
                username: referrerUrl.username,
              };
            } catch (err) {
              console.error('Error parsing referrer URL:', err);
              return null;
            }
          }
          return null;
        };
    console.log(123);
    console.log(localStorage.getItem(`sourceSent_${user.id}`));
    console.log(!localStorage.getItem(`sourceSent_${user.id}`));
    console.log(determineSource());
    
        // Check if source has already been sent for this session
        const sourceSentFlag = `sourceSent_${user.id}`;
        if (!localStorage.getItem(sourceSentFlag)) {
          const source = determineSource();
          if (source) {
            console.log('Source:', source);
            sendSourceToBackend(source).then(() => {
              localStorage.setItem(sourceSentFlag, 'true');
            });
          }
          else{
            console.log('Source:', source);
            sendSourceToBackend("").then(() => {
              localStorage.setItem(sourceSentFlag, 'true');
            });
          }

        }
      }, [location.search, user.id]);



  return (
    <div className="home-page">
      <header className="home-header">
        <h1>Welcome to MyApp</h1>
        <p>Your gateway to seamless communication.</p>
        <div className="home-buttons">
          <Link to="/login" className="btn btn-primary">Login</Link>
          <Link to="/signup" className="btn btn-secondary">Sign Up</Link>
        </div>
      </header>
    </div>
  );
}

export default HomePage;
