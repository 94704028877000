// src/components/pages/ChatPage.js

import React, { useState, useRef, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../../services/apiService';
import Modal from 'react-modal';
import './ChatPage.css';
import { AuthContext } from '../../contexts/AuthContext';
import ReviewModal from '../Modals/ReviewModal';
import HelpModal from '../Modals/HelpModal';
import ShareModal from '../Modals/ShareModal';
import CryptoJS from 'crypto-js';
import { v4 as uuidv4 } from 'uuid'; 
import { MdFullscreen } from 'react-icons/md';
import { FaTimes } from 'react-icons/fa';

// Encryption key from environment variables
const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY;

// List of supported services
const supportedServices = JSON.parse(process.env.REACT_APP_SUPPORTED_SERVICES);

// Mapping between service names and backend keys
const serviceKeyMap = JSON.parse(process.env.REACT_APP_SERVICE_KEY_MAP);

// Encryption utilities
const encryptData = (data) => {
  if (!encryptionKey) {
    throw new Error('Encryption key is missing. Please set REACT_APP_ENCRYPTION_KEY in your .env file.');
  }
  return CryptoJS.AES.encrypt(data, encryptionKey).toString();
};

const decryptData = (cipherText) => {
  if (!encryptionKey) {
    throw new Error('Encryption key is missing. Please set REACT_APP_ENCRYPTION_KEY in your .env file.');
  }
  try {
    const bytes = CryptoJS.AES.decrypt(cipherText, encryptionKey);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    if (!decrypted) {
      throw new Error('Decryption failed. Invalid ciphertext or encryption key.');
    }
    return decrypted;
  } catch (error) {
    console.error('Error during decryption:', error);
    throw new Error('Failed to decrypt data.');
  }
};

// Utility to read a file as Data URL
const readFileAsDataURL = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

// IndexedDB utilities
let dbPromise = null;

const initIndexedDB = () => {
  if (!dbPromise) {
    dbPromise = new Promise((resolve, reject) => {
      const request = window.indexedDB.open('myAppDB', 2);
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('files')) {
          db.createObjectStore('files', { keyPath: 'path' });
        }
      };
      request.onsuccess = () => {
        resolve(request.result);
      };
      request.onerror = () => {
        reject(request.error);
      };
    });
  }
  return dbPromise;
};

const dbTransaction = async (storeName, mode) => {
  const db = await initIndexedDB();
  const tx = db.transaction(storeName, mode);
  return tx.objectStore(storeName);
};

const indexedDBGet = async (key) => {
  const store = await dbTransaction('files', 'readonly');
  return new Promise((resolve, reject) => {
    const req = store.get(key);
    req.onsuccess = () => resolve(req.result);
    req.onerror = () => reject(req.error);
  });
};

const indexedDBGetAllKeys = async () => {
  const store = await dbTransaction('files', 'readonly');
  return new Promise((resolve, reject) => {
    const req = store.getAllKeys();
    req.onsuccess = () => resolve(req.result);
    req.onerror = () => reject(req.error);
  });
};

const indexedDBPut = async (record) => {
  const store = await dbTransaction('files', 'readwrite');
  return new Promise((resolve, reject) => {
    const req = store.put(record);
    req.onsuccess = () => resolve(req.result);
    req.onerror = () => reject(req.error);
  });
};

const indexedDBDelete = async (key) => {
  const store = await dbTransaction('files', 'readwrite');
  return new Promise((resolve, reject) => {
    const req = store.delete(key);
    req.onsuccess = () => resolve();
    req.onerror = () => reject(req.error);
  });
};

const indexedDBListByPrefix = async (prefix) => {
  const keys = await indexedDBGetAllKeys();
  return keys.filter(k => k.startsWith(prefix));
};

// Encryption and decryption for files using IndexedDB
const readEncryptedFile = async (path) => {
  const record = await indexedDBGet(path);
  if (!record) {
    const err = new Error('NotFoundError');
    err.name = 'NotFoundError';
    throw err;
  }
  const encryptedContent = record.content;
  const decryptedContent = decryptData(encryptedContent);
  return decryptedContent;
};

const writeEncryptedFile = async (path, data) => {
  const encryptedContent = encryptData(data);
  const record = { path, content: encryptedContent };
  await indexedDBPut(record);
};

// File path utilities using unique conversation IDs
const conversationFilePath = (conversationId, fileName) => `conversations/${conversationId}/${fileName}`;
const folderFilePath = (folderName, fileName) => `folders/${folderName}/${fileName}`;
const favoritesFilePath = () => `favorites.json`;

// Utility function to sanitize file names by replacing spaces with underscores
const sanitizeFileName = (name) => name.replace(/\s+/g, '_');

const ChatPage = () => {
  const { user } = useContext(AuthContext);
  const actualUser = user || { id: "777777777", iat: 1733253711 }; // Fallback for unauthenticated users

  // State management
  const [prompt, setPrompt] = useState('');
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [isLimitReached, setIsLimitReached] = useState(false);
  const [currentClickCount, setCurrentClickCount] = useState(0);
  const [clickLimit, setClickLimit] = useState(0);

  const [conversations, setConversations] = useState([]);
  const [currentConversation, setCurrentConversation] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  const fileInputRef = useRef(null);
  const messagesEndRef = useRef(null);
  const iframeRef = useRef(null);
  const uploadFileInputRef = useRef(null);

  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');

  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [renameConversation, setRenameConversation] = useState(null);
  const [newConversationName, setNewConversationName] = useState('');

  const [folders, setFolders] = useState([]);
  const [expandedFolders, setExpandedFolders] = useState({});
  const [filesInFolders, setFilesInFolders] = useState({});

  const imagePathToDataURLRef = useRef(new Map());

  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const [indexContent, setIndexContent] = useState(null);
  const [currentUploadFolder, setCurrentUploadFolder] = useState(null);

  const [isConversationsCollapsed, setIsConversationsCollapsed] = useState(false);
  const [displayedConversations, setDisplayedConversations] = useState(7);

  const [isFoldersCollapsed, setIsFoldersCollapsed] = useState(false);
  const [displayedFolders, setDisplayedFolders] = useState(7);

  const [favoriteConversations, setFavoriteConversations] = useState([]);
  const [isFavoriteAppsCollapsed, setIsFavoriteAppsCollapsed] = useState(false);

  // API Key Management
  // State to hold API key statuses
  const [apiKeyStatus, setApiKeyStatus] = useState(() => {
    // Load from localStorage if available
    const storedStatus = localStorage.getItem(`apiKeyStatus_${actualUser.id}`);
    return storedStatus ? JSON.parse(storedStatus) : {};
  });

  // API Key Presence Status (True/False)
  const [hasApiKey, setHasApiKey] = useState(() => {
    return Object.values(apiKeyStatus).some(status => status === true);
  });

  // Initialize selectedService from localStorage or default to first service
  const [selectedService, setSelectedService] = useState(() => {
    const savedService = localStorage.getItem('selectedService');
    return supportedServices.includes(savedService) ? savedService : supportedServices[0];
  });




  const [isImportDropdownOpen, setIsImportDropdownOpen] = useState(false);
  const [isImportLinkModalOpen, setIsImportLinkModalOpen] = useState(false);
  const [importUrl, setImportUrl] = useState('');

  // Reference for the import link input (optional)
  const importLinkInputRef = useRef(null);



// Function to handle importing from URL
const handleImportFromLink = async () => {
  if (!importUrl.trim()) {
    alert('Please enter a valid URL.');
    return;
  }

  try {
    // Fetch the file from the URL
    const response = await fetch(importUrl);
    if (!response.ok) {
      throw new Error(`Failed to fetch the file. Status: ${response.status}`);
    }

    const fileContent = await response.text(); // Assuming the file is text-based

    // Decrypt the file content
    const decrypted = decryptData(fileContent);
    const data = JSON.parse(decrypted);

    // Validate the imported data
    if (!data.name) {
      throw new Error('Imported conversation data must include a name.');
    }

    // Ensure unique conversation ID
    const existingConversationIds = conversations.map((c) => c.id);
    let importedId = data.id || uuidv4();
    if (existingConversationIds.includes(importedId)) {
      importedId = uuidv4();
    }
    data.id = importedId;

    // Overwrite conversation.json entry with updated ID and allowedUsers
    const conversationJsonEntry = data.entries.find((e) => e.name === 'conversation.json');
    if (conversationJsonEntry) {
      const convoDecrypted = decryptData(conversationJsonEntry.content);
      const convoObj = JSON.parse(convoDecrypted);

      // Update ID & allowedUsers
      convoObj.id = data.id;
      convoObj.allowedUsers = [actualUser.id];
      convoObj.lastModified = Date.now();

      // Ensure unique conversation name
      const uniqueName = await getUniqueConversationName(data.name);
      convoObj.name = uniqueName;

      // Re-encrypt and update the content
      const newConvoContent = JSON.stringify(convoObj, null, 2);
      conversationJsonEntry.content = encryptData(newConvoContent);

      // Sync data.name with unique name
      data.name = uniqueName;
    }

    // Import into IndexedDB
    await importSingleConversation(data);

    alert(`Imported conversation successfully as "${data.name}"!`);
    await loadConversations();

    // Reset the modal state
    setIsImportLinkModalOpen(false);
    setImportUrl('');
  } catch (err) {
    console.error('Error importing from link:', err);
    alert('Failed to import conversation from the provided URL. Please ensure the URL is correct and the file format is valid.');
  }
};


  // Save selectedService to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('selectedService', selectedService);
  }, [selectedService]);

  const [isKeyModalOpen, setIsKeyModalOpen] = useState(false);
  const [keyInput, setKeyInput] = useState('');

  const [hasShownReviewModal, setHasShownReviewModal] = useState(() => {
    return JSON.parse(localStorage.getItem(`hasShownReviewModal_${actualUser.id}`)) || false;
  });

  const [reviewModalShow, setreviewModalShow] = useState(false);
  const [helpModalShow, sethelpModalShow] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const shareUrl = 'https://yobino.com';
  const title = 'Check out this awesome app!';
  const description = 'This app does amazing things. You should definitely try it out!';

  const handleOpenReview = () => setreviewModalShow(true);
  const handleCloseReview = () => setreviewModalShow(false);

  const handleOpenHelp = () => sethelpModalShow(true);
  const handleCloseHelp = () => sethelpModalShow(false);

  const conversationCountRef = useRef(0);

  useEffect(() => {
    conversationCountRef.current = conversations.length;
  }, [conversations.length]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const saveConversation = async () => {
      if (currentConversation) {
        try {
          const originalMessages = messages.map(({ sender, content, timestamp }) => ({
            sender,
            content,
            timestamp,
          }));

          let allowedUsers = [actualUser.id];

          const conversationData = {
            id: currentConversation.id, // Include unique ID
            name: currentConversation.name, // Include name
            allowedUsers,
            messages: originalMessages,
            lastModified: Date.now(), // Update lastModified
          };
          await writeFile('conversation.json', JSON.stringify(conversationData, null, 2), currentConversation.id);

          console.log(`Conversation "${currentConversation.name}" saved automatically.`);

          // Update the lastModified in the conversations state
          setConversations((prevConvos) =>
            prevConvos.map((convo) =>
              convo.id === currentConversation.id ? { ...convo, lastModified: Date.now() } : convo
            )
          );
        } catch (err) {
          console.error('Error auto-saving conversation:', err);
        }
      }
    };

    saveConversation();
  }, [messages, currentConversation, actualUser.id]);

  const checkUserLimit = async () => {
    try {
      const response = await api.get('/user/userStatus');
      const { isSubscribed, currentRequestCount, requestLimit, isLimitReached: limitReachedBackend, currentClickCount: clickCountBackend, clickLimit: clickLimitBackend } = response.data;

      setIsLimitReached(limitReachedBackend);
      setCurrentClickCount(clickCountBackend);
      setClickLimit(clickLimitBackend);
    } catch (err) {
      console.error('Error checking user limit:', err);
    }
  };

  useEffect(() => {
    const fetchApiKeyStatus = async () => {
      if (actualUser.id !== "777777777") { // Authenticated users
        try {
          const response = await api.get('/user/apiKeyStatus');
          const apiKeyStatusResponse = response.data; // { openaiApiKey: true, geminiApiKey: false, ... }

          setApiKeyStatus(apiKeyStatusResponse);
          setHasApiKey(Object.values(apiKeyStatusResponse).some(status => status === true));

          // Save to localStorage
          localStorage.setItem(`apiKeyStatus_${actualUser.id}`, JSON.stringify(apiKeyStatusResponse));
        } catch (err) {
          console.error('Error fetching API key status:', err);
        }
      } else {
        // Unauthenticated users: load from localStorage if available
        const storedStatus = localStorage.getItem(`apiKeyStatus_${actualUser.id}`);
        if (storedStatus) {
          setApiKeyStatus(JSON.parse(storedStatus));
          const parsedStatus = JSON.parse(storedStatus);
          const hasKey = Object.values(parsedStatus).some(status => status === true);
          setHasApiKey(hasKey);
        }
      }
    };

    fetchApiKeyStatus();
  }, [actualUser.id]);

  const loadFavorites = async (userId) => {
    try {
      const content = await readFile('favorites.json');
      if (!content) {
        setFavoriteConversations([]);
        return;
      }
      const allFavorites = JSON.parse(content);
      if (typeof allFavorites !== 'object' || allFavorites === null) {
        setFavoriteConversations([]);
        return;
      }

      if (userId !== "777777777" && allFavorites["777777777"]) {
        const oldFavorites = Array.isArray(allFavorites["777777777"]) ? allFavorites["777777777"] : [];
        if (!Array.isArray(allFavorites[userId])) {
          allFavorites[userId] = [];
        }
        allFavorites[userId] = Array.from(new Set([...allFavorites[userId], ...oldFavorites]));
        delete allFavorites["777777777"];
        await writeFile('favorites.json', JSON.stringify(allFavorites, null, 2));
      }

      const userFavorites = Array.isArray(allFavorites[userId]) ? allFavorites[userId] : [];
      setFavoriteConversations(userFavorites);
    } catch (err) {
      console.error('Error loading favorites:', err);
      setFavoriteConversations([]);
    }
  };

  const saveFavorites = async (userId, favorites) => {
    let allFavorites = {};
    try {
      const content = await readFile('favorites.json');
      if (content) {
        try {
          allFavorites = JSON.parse(content);
          if (typeof allFavorites !== 'object' || allFavorites === null) {
            allFavorites = {};
          }
        } catch {
          allFavorites = {};
        }
      }
      allFavorites[userId] = favorites;
      await writeFile('favorites.json', JSON.stringify(allFavorites, null, 2));
    } catch (err) {
      console.error('Error saving favorites:', err);
      alert('Failed to save favorites.');
    }
  };

  const toggleFavoriteConversation = async (conversation) => {
    const isFavorite = favoriteConversations.includes(conversation.id);
    let updatedFavorites;

    if (isFavorite) {
      updatedFavorites = favoriteConversations.filter((favId) => favId !== conversation.id);
    } else {
      updatedFavorites = [...favoriteConversations, conversation.id];
    }

    setFavoriteConversations(updatedFavorites);

    await saveFavorites(actualUser.id, updatedFavorites);
  };

  const removeFavoriteConversation = async (conversationId) => {
    const updatedFavorites = favoriteConversations.filter((favId) => favId !== conversationId);
    setFavoriteConversations(updatedFavorites);

    await saveFavorites(actualUser.id, updatedFavorites);
  };

  const loadConversations = async () => {
    try {
      const allKeys = await indexedDBListByPrefix('conversations/');
      const conversationIds = new Set(allKeys.map(k => {
        const parts = k.split('/');
        return parts.length > 1 ? parts[1] : null;
      }).filter(Boolean));

      const convs = [];
      for (const convoId of conversationIds) {
        const convoContent = await readFile('conversation.json', convoId);
        if (!convoContent || !convoContent.trim()) continue;
        try {
          const convoData = JSON.parse(convoContent);
          let shouldInclude = false;
          if (convoData.allowedUsers && convoData.allowedUsers.includes(actualUser.id)) {
            shouldInclude = true;
          } else if (convoData.allowedUsers && convoData.allowedUsers.includes("777777777")) {
            convoData.allowedUsers = convoData.allowedUsers.map(uid => uid === "777777777" ? actualUser.id : uid);
            await writeFile('conversation.json', JSON.stringify(convoData, null, 2), convoId);
            shouldInclude = true;
          }

          if (shouldInclude) {
            const lastModified = convoData.lastModified || Date.now(); // Read lastModified from data
            convs.push({
              id: convoData.id || convoId, // Ensure ID is present
              name: convoData.name || `Conversation ${convs.length + 1}`, // Fallback name
              lastModified,
            });
          }
        } catch {
          console.warn(`conversation.json malformed for ${convoId}. Skipping.`);
        }
      }

      convs.sort((a, b) => b.lastModified - a.lastModified); // Sort by lastModified descending
      setConversations(convs);
      setDisplayedConversations(7);
    } catch (err) {
      console.error('Error loading conversations:', err);
      alert('Failed to load conversations.');
    }
  };

  const loadUploadedImages = async () => {
    try {
      const imageKeys = await indexedDBListByPrefix('folders/images/');
      const images = [];
      for (const imgKey of imageKeys) {
        const record = await indexedDBGet(imgKey);
        if (!record) continue;
        try {
          const decrypted = decryptData(record.content);
          // Replace underscores with spaces for display name
          const originalName = imgKey.split('/').pop().replace(/_/g, ' ');
          const relativePath = `/${imgKey.replace('folders/', '')}`;
          images.push({ name: originalName, path: relativePath, url: decrypted });
          imagePathToDataURLRef.current.set(relativePath, decrypted);
        } catch (err) {
          console.error(`Error decrypting image ${imgKey}:`, err);
        }
      }
      setUploadedImages(images);
    } catch (err) {
      console.error('Error loading uploaded images:', err);
      alert('Failed to load uploaded images.');
    }
  };

  const loadFolders = async () => {
    try {
      const keys = await indexedDBListByPrefix('folders/');
      const folderNames = new Set(keys.map(k => k.split('/')[1]).filter(name => name && name !== 'images'));
      const folderEntries = [];
      for (const fname of folderNames) {
        const fContent = await readFile('folder.json', null, fname);
        let lastModified = 0;
        if (fContent && fContent.trim()) {
          try {
            const folderData = JSON.parse(fContent);
            lastModified = folderData.lastModified || 0;
          } catch {
            console.warn(`folder.json malformed for ${fname}`);
          }
        }
        folderEntries.push({
          name: fname,
          lastModified,
        });
      }
      folderEntries.sort((a, b) => b.lastModified - a.lastModified); // Sort by lastModified descending
      setFolders(folderEntries);
      setDisplayedFolders(7);
    } catch (err) {
      console.error('Error loading folders:', err);
      alert('Failed to load folders.');
    }
  };

  const handleSelectDirectory = async () => {
    try {
      await initIndexedDB();
      await loadConversations();
      await loadFavorites(actualUser.id);

      setIsLoadingImages(true);
      await loadUploadedImages();
      setIsLoadingImages(false);

      await loadFolders();

      await checkUserLimit();
    } catch (err) {
      console.error('Error initializing IndexedDB:', err);
      alert('Failed to initialize storage.');
    }
  };

  useEffect(() => {
    handleSelectDirectory();
  }, []);

  useEffect(() => {
    if (conversations.length >= 7 && !hasShownReviewModal) {
      setreviewModalShow(true);
      setHasShownReviewModal(true);
      localStorage.setItem(`hasShownReviewModal_${actualUser.id}`, JSON.stringify(true));
    }
  }, [conversations.length, hasShownReviewModal, actualUser.id]);

  const handleCreateConversation = async () => {
    const convoName = window.prompt('Enter a name for the new conversation:', `Conversation ${conversations.length + 1}`);
    if (!convoName) {
      alert('Conversation name is required.');
      return;
    }

    const invalidChars = /[<>:"/\\|?*\x00-\x1F]/g;
    if (invalidChars.test(convoName)) {
      alert('Conversation name contains invalid characters.');
      return;
    }

    try {
      // Check if any conversation already has this name
      const nameExists = conversations.some(convo => convo.name === convoName);
      if (nameExists) {
        alert('A conversation with this name already exists.');
        return;
      }

      const uniqueId = uuidv4(); // Generate unique ID for the conversation

      const conversationData = {
        id: uniqueId,
        name: convoName,
        allowedUsers: [actualUser.id],
        messages: [],
        lastModified: Date.now(), // Initialize lastModified
      };
      await writeFile('conversation.json', JSON.stringify(conversationData, null, 2), uniqueId);
      const newConversation = { id: uniqueId, name: convoName, lastModified: Date.now() };
      setConversations((prev) => [newConversation, ...prev]);
      setCurrentConversation(newConversation);
      setMessages([]);
      setIndexContent(null);
      setDisplayedConversations((prev) => prev + 1);
      alert(`Conversation "${convoName}" created successfully.`);

      await checkUserLimit();
    } catch (err) {
      console.error('Error creating conversation:', err);
      alert('Failed to create conversation.');
    }
  };

  const openRenameConversationModal = (conversation) => {
    setRenameConversation(conversation);
    setNewConversationName(conversation.name);
    setIsRenameModalOpen(true);
  };

  const closeRenameConversationModal = () => {
    setIsRenameModalOpen(false);
    setRenameConversation(null);
    setNewConversationName('');
  };

  const handleRenameConversation = async () => {
    if (!newConversationName.trim()) {
      alert('Conversation name cannot be empty.');
      return;
    }

    const invalidChars = /[<>:"/\\|?*\x00-\x1F]/g;
    if (invalidChars.test(newConversationName)) {
      alert('Conversation name contains invalid characters.');
      return;
    }

    try {
      const existing = conversations.find(convo => convo.name === newConversationName.trim());
      if (existing) {
        alert('A conversation with this name already exists.');
        return;
      }

      const convoId = renameConversation.id;
      const convoData = {
        id: convoId,
        name: newConversationName.trim(),
        allowedUsers: [actualUser.id],
        messages: messages.map(({ sender, content, timestamp }) => ({ sender, content, timestamp })),
        lastModified: Date.now(),
      };

      await writeFile('conversation.json', JSON.stringify(convoData, null, 2), convoId);

      setConversations((prevConvos) =>
        prevConvos.map((convo) =>
          convo.id === convoId ? { ...convo, name: newConversationName.trim(), lastModified: Date.now() } : convo
        )
      );

      if (currentConversation && currentConversation.id === convoId) {
        setCurrentConversation({ ...currentConversation, name: newConversationName.trim() });
      }

      alert(`Conversation renamed to "${newConversationName.trim()}" successfully.`);
      closeRenameConversationModal();
      await checkUserLimit();
    } catch (err) {
      console.error('Error renaming conversation:', err);
      alert('Failed to rename conversation.');
    }
  };

  const handleDeleteConversation = async (conversation) => {
    if (!window.confirm(`Are you sure you want to delete the conversation "${conversation.name}"?`)) {
      return;
    }

    try {
      const convoId = conversation.id;
      const keys = await indexedDBListByPrefix(`conversations/${convoId}/`);
      for (const key of keys) {
        await indexedDBDelete(key);
      }

      setConversations((prevConvos) => prevConvos.filter((convo) => convo.id !== convoId));

      if (favoriteConversations.includes(convoId)) {
        const updatedFavorites = favoriteConversations.filter((favId) => favId !== convoId);
        setFavoriteConversations(updatedFavorites);
        await saveFavorites(actualUser.id, updatedFavorites);
      }

      if (currentConversation && currentConversation.id === convoId) {
        setCurrentConversation(null);
        setMessages([]);
        setIndexContent(null);
      }

      alert(`Conversation "${conversation.name}" deleted successfully.`);
      await checkUserLimit();
    } catch (err) {
      console.error('Error deleting conversation:', err);
      alert('Failed to delete conversation.');
    }
  };

  const handleLoadConversation = async (conversation) => {
    try {
      const convoContent = await readFile('conversation.json', conversation.id);
      if (!convoContent || !convoContent.trim()) {
        alert('Conversation not found or malformed.');
        return;
      }
      const convoData = JSON.parse(convoContent);

      if (!convoData.allowedUsers || !convoData.allowedUsers.includes(actualUser.id)) {
        alert('You are not authorized to access this conversation.');
        return;
      }

      const loadedMessages = convoData.messages;
      if (!Array.isArray(loadedMessages)) {
        throw new Error('Invalid conversation format.');
      }

      const mappedMessages = loadedMessages.map((msg) => {
        if (msg.sender === 'assistant') {
          const modifiedDisplayContent = msg.content;
          return { ...msg, displayContent: modifiedDisplayContent };
        }
        return { ...msg, displayContent: msg.content };
      });

      setIndexContent(null); // Reset index content before loading new conversation
      setMessages(mappedMessages);
      setCurrentConversation(conversation);
      setError(null);

      await getConversationIndexContent(conversation);
    } catch (err) {
      console.error('Error loading conversation:', err);
      alert('Failed to load conversation.');
      setIndexContent(null);
    }
  };

  const handleSelectConversation = async (conversation, index) => {
    if (isLimitReached && index >= 3) {
      return;
    }
    await handleLoadConversation(conversation);
  };

  const handleSend = async () => {
    if (!prompt.trim()) {
      setError('Please enter a valid prompt.');
      return;
    }

    if (!currentConversation) {
      setError('Please select or create a conversation first.');
      return;
    }

    // Check if API key is present based on service status
    const isApiKeyPresent = apiKeyStatus[serviceKeyMap[selectedService]];
    // console.log('isApiKeyPresent');
    // console.log(isApiKeyPresent);
    // console.log('isApiKeyPresent');
    
    if (
      (!isApiKeyPresent)
    ) {
      setIsKeyModalOpen(true);
      return;
    }



    setLoading(true);
    setError(null);

    try {
      const lastExchange = [];
      if (messages.length >= 2) {
        const lastUserMessage = messages[messages.length - 2];
        const lastAssistantMessage = messages[messages.length - 1];
        if (lastUserMessage.sender === 'user' && lastAssistantMessage.sender === 'assistant') {
          lastExchange.push(
            { ...lastUserMessage, content: lastUserMessage.content },
            { ...lastAssistantMessage, content: lastAssistantMessage.content }
          );
        }
      }

      const userMessageContent = prompt;
      const requestPayload = {
        prompt: userMessageContent,
        messages: lastExchange,
        conversationName: currentConversation.name, 
        service: selectedService, // Include selected service
      };

      if (actualUser.id === "777777777") {
        // Unauthenticated users: include all API keys from localStorage
        const apiKeys = {};
        supportedServices.forEach(service => {
          const keyName = `${service.toLowerCase()}ApiKey`;
          const key = localStorage.getItem(keyName);
          if (key) {
            apiKeys[serviceKeyMap[service]] = key;
          }
        });
        requestPayload.apiKeys = apiKeys;
      } else {
        // Authenticated users: backend manages API keys securely
        // No need to include the API key in the frontend
      }

      const response = await api.post('/request', requestPayload);

      const assistantCode = response.data.code;

      const assistantMessage = {
        sender: 'assistant',
        content: assistantCode,
        displayContent: assistantCode,
        timestamp: new Date().toISOString(),
      };
      const userMessage = {
        sender: 'user',
        content: userMessageContent,
        displayContent: userMessageContent,
        timestamp: new Date().toISOString(),
      };
      setMessages((prev) => [...prev, userMessage, assistantMessage]);

      await saveAssistantCode(assistantCode);
      await getConversationIndexContent(currentConversation);

      await checkUserLimit();

      setPrompt('');
    } catch (err) {
      if (err.response) {
        const errorMsg = err.response.data.error || 'An error occurred. Please try again.';
        setError(errorMsg);
        if (errorMsg === 'Request limit reached. Upgrade your account.') {
          setIsLimitReached(true);
        }
      } else if (err.request) {
        setError('No response from server. Please try again later.');
      } else {
        setError('An unexpected error occurred.');
      }
    } finally {
      setLoading(false);
    }
  };

  const extractImagePaths = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const images = doc.querySelectorAll('img');
    const imagePaths = [];

    images.forEach((img) => {
      let src = img.getAttribute('src');
      if (src && !src.startsWith('data:') && !src.startsWith('http')) {
        src = src.startsWith('/') ? src : `/${src}`;
        src = decodeURIComponent(src);
        imagePaths.push(src);
      }
    });
    return imagePaths;
  };

  const saveAssistantCode = async (codeContent) => {
    if (!currentConversation) {
      alert('Please select or create a conversation first.');
      return;
    }

    try {
      let modifiedCodeContent = codeContent;
      const imagePaths = extractImagePaths(codeContent);

      console.log('Image Paths Extracted:', imagePaths);
      for (const imagePath of imagePaths) {
        console.log('Processing Image Path:', imagePath);
        // Remove leading slash to match IndexedDB storage path
        const dbPath = `folders${imagePath}`;
        const record = await indexedDBGet(dbPath);
        if (record) {
          const dataURL = decryptData(record.content);
          console.log(`Data URL for ${imagePath}:`, dataURL.slice(0, 50) + '...'); // Log first 50 chars
          const escapedImagePath = imagePath.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
          const regex = new RegExp(escapedImagePath, 'g');
          modifiedCodeContent = modifiedCodeContent.replace(regex, dataURL);
        } else {
          console.error(`Image file ${imagePath} not found in IndexedDB.`);
        }
      }

      console.log('Modified Code Content:', modifiedCodeContent);
      await writeFile('index.html', modifiedCodeContent, currentConversation.id);
      console.log('index.html written successfully.');

      // Update lastModified after saving
      setConversations((prevConvos) =>
        prevConvos.map((convo) =>
          convo.id === currentConversation.id ? { ...convo, lastModified: Date.now() } : convo
        )
      );
    } catch (err) {
      console.error('Error saving code:', err);
      alert('Failed to save code.');
    }
  };

  const handleResetConversation = () => {
    if (window.confirm('Are you sure you want to reset the current conversation?')) {
      setMessages([]);
      setIndexContent(null);
      checkUserLimit();
    }
  };

  const handleUpgrade = () => {
    navigate('/subscribe');
    setIsLimitReached(false);
  };

  const openCreateFolderModal = () => {
    setNewFolderName('');
    setIsCreateFolderModalOpen(true);
  };

  const closeCreateFolderModal = () => {
    setIsCreateFolderModalOpen(false);
  };

  const handleCreateFolder = async () => {
    if (!newFolderName.trim()) {
      alert('Folder name cannot be empty.');
      return;
    }

    const invalidChars = /[<>:"/\\|?*\x00-\x1F]/g;
    if (invalidChars.test(newFolderName)) {
      alert('Folder name contains invalid characters.');
      return;
    }

    if (newFolderName.toLowerCase() === 'images') {
      alert('A folder named "images" already exists and is reserved for image uploads.');
      return;
    }

    try {
      const existingKeys = await indexedDBListByPrefix(`folders/${newFolderName}/`);
      if (existingKeys.length > 0) {
        alert('A folder with this name already exists.');
        return;
      }

      const folderMetadata = {
        name: newFolderName,
        createdAt: Date.now(),
        lastModified: Date.now(),
      };
      await writeFile('folder.json', JSON.stringify(folderMetadata, null, 2), null, newFolderName);

      const newFolder = { name: newFolderName, lastModified: folderMetadata.lastModified };
      setFolders((prev) => [newFolder, ...prev]);
      alert(`Folder "${newFolderName}" created successfully.`);
      closeCreateFolderModal();
      setDisplayedFolders((prev) => prev + 1);
    } catch (err) {
      console.error('Error creating folder:', err);
      alert('Failed to create folder.');
    }
  };

  const toggleFolderExpansion = async (folderName) => {
    setExpandedFolders((prev) => ({
      ...prev,
      [folderName]: !prev[folderName],
    }));

    if (!expandedFolders[folderName] && !filesInFolders[folderName]) {
      try {
        const keys = await indexedDBListByPrefix(`folders/${folderName}/`);
        const files = keys.map(k => k.split('/').pop()).filter(f => f !== 'folder.json');
        setFilesInFolders((prev) => ({
          ...prev,
          [folderName]: files,
        }));
      } catch (err) {
        console.error(`Error loading files for folder "${folderName}":`, err);
        alert(`Failed to load files for folder "${folderName}".`);
      }
    }
  };

  const handleSelectFile = (folderName, fileName) => {
    const sanitizedFileName = sanitizeFileName(fileName);
    const relativePath = `/${folderName}/${sanitizedFileName}`;
    insertAtCursor('"' + relativePath + '" ');
  };

  const insertAtCursor = (insertText) => {
    const textarea = document.getElementById('prompt-textarea');
    if (!textarea) return;

    const startPos = textarea.selectionStart;
    const endPos = textarea.selectionEnd;
    const before = prompt.substring(0, startPos);
    const after = prompt.substring(endPos, prompt.length);
    const newText = before + insertText + after;
    setPrompt(newText);

    setTimeout(() => {
      textarea.selectionStart = textarea.selectionEnd = startPos + insertText.length;
      textarea.focus();
    }, 0);
  };

  const handleTextareaKeyDown = (e) => {
    // if (!e.ctrlKey && !e.altKey && !e.metaKey) {
    //   e.preventDefault();
    //   // Optionally, open a file selection modal or similar
    // }
  };

  const getConversationIndexContent = async (conversation) => {
    if (!conversation) {
      setIndexContent(null);
      return;
    }

    try {
      const idxContent = await readFile('index.html', conversation.id);
      if (idxContent && idxContent.trim()) {
        const modifiedContent = injectIframeClickListener(idxContent); 
        setIndexContent(modifiedContent);
      } else {
        setIndexContent(null);
      }
    } catch (err) {
      console.error('Error getting index.html content:', err);
      setIndexContent(null);
    }
  };

  // Function to inject click listener script into HTML content
  const injectIframeClickListener = (htmlContent) => {
    const script = `
      <script>
        document.addEventListener('click', () => {
          parent.postMessage({ type: 'iframe-click' }, '*');
        });
      </script>
    `;
    if (htmlContent.includes('</body>')) {
      return htmlContent.replace('</body>', `${script}</body>`);
    } else {
      return htmlContent + script;
    }
  };

  useEffect(() => {
    const handleMessage = async (event) => {
      if (iframeRef.current && event.source === iframeRef.current.contentWindow) {
        const { type, payload } = event.data;

        switch (type) {
          case 'READ_FILE':
            try {
              const data = await readFile(payload.filename, currentConversation?.id || null);
              event.source.postMessage({ type: 'FILE_CONTENT', payload: { filename: payload.filename, content: data } }, '*');
            } catch (err) {
              console.error(`Error reading file ${payload.filename}:`, err);
              event.source.postMessage({ type: 'ERROR', payload: `Failed to read file ${payload.filename}.` }, '*');
            }
            break;
          case 'WRITE_FILE':
            try {
              await writeFile(payload.filename, payload.content, currentConversation?.id || null);
              event.source.postMessage({ type: 'WRITE_SUCCESS', payload: { filename: payload.filename } }, '*');
            } catch (err) {
              console.error(`Error writing to file ${payload.filename}:`, err);
              event.source.postMessage({ type: 'ERROR', payload: `Failed to write file ${payload.filename}.` }, '*');
            }
            break;
          case 'iframe-click':
            handleClick(); // Increment click count when iframe is clicked
            break;
          default:
            console.warn('Unknown message type:', type);
            break;
        }
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [currentConversation]);

  const lastAssistantMessage = messages.slice().reverse().find((msg) => msg.sender === 'assistant');

  const handlePerFolderFileUpload = async (event) => {
    const files = event.target.files;
    if (files.length === 0 || !currentUploadFolder) return;

    try {
      for (const file of files) {
        let fileName = file.name;
        // Sanitize file name by replacing spaces with underscores
        let sanitizedFileName = sanitizeFileName(fileName);
        const keys = await indexedDBListByPrefix(`folders/${currentUploadFolder}/`);
        while (keys.includes(`folders/${currentUploadFolder}/${sanitizedFileName}`)) {
          const nameParts = sanitizedFileName.split('.');
          const extension = nameParts.pop();
          const baseName = nameParts.join('.');
          fileName = `${baseName}(1).${extension}`;
          sanitizedFileName = sanitizeFileName(fileName);
        }
        const dataURL = await readFileAsDataURL(file);
        await writeFile(sanitizedFileName, dataURL, null, currentUploadFolder);
        console.log(`File uploaded to IndexedDB: folders/${currentUploadFolder}/${sanitizedFileName}`);
        if (currentUploadFolder === 'images') {
          imagePathToDataURLRef.current.set(`/images/${sanitizedFileName}`, dataURL);
          console.log(`ImagePathToDataURLRef updated: /images/${sanitizedFileName}`);
        }
      }

      if (currentUploadFolder === 'images') {
        setIsLoadingImages(true);
        await loadUploadedImages();
        setIsLoadingImages(false);
        console.log('Uploaded images loaded:', uploadedImages);
      }

      const keysAfterUpload = await indexedDBListByPrefix(`folders/${currentUploadFolder}/`);
      const filesList = keysAfterUpload.map(k => k.split('/').pop()).filter(f => f !== 'folder.json');
      setFilesInFolders((prev) => ({
        ...prev,
        [currentUploadFolder]: filesList,
      }));
      console.log(`Files in folder ${currentUploadFolder}:`, filesList);

      alert('Files uploaded successfully!');
      setCurrentUploadFolder(null);
      if (uploadFileInputRef.current) {
        uploadFileInputRef.current.value = '';
      }
    } catch (err) {
      console.error('Error uploading files:', err);
      alert('Failed to upload files.');
    }
  };

  const toggleConversationsCollapse = () => {
    setIsConversationsCollapsed(!isConversationsCollapsed);
  };

  const toggleFoldersCollapse = () => {
    setIsFoldersCollapsed(!isFoldersCollapsed);
  };

  const toggleFavoriteAppsCollapse = () => {
    setIsFavoriteAppsCollapsed(!isFavoriteAppsCollapsed);
  };

  const [isSendingClicks, setIsSendingClicks] = useState(false);
  const isSendingClicksRef = useRef(false);

  useEffect(() => {
    isSendingClicksRef.current = isSendingClicks;
  }, [isSendingClicks]);

  useEffect(() => {
    const sendSourceToBackend = async (source) => {
      try {
        await api.post('/visit', { source: JSON.stringify(source), userId: actualUser.id });
      } catch (error) {
        console.error('Error sending source to backend:', error);
      }
    };

    const getUTMParams = () => {
      const params = new URLSearchParams(location.search);
      const utm_source = params.get('utm_source');
      const utm_medium = params.get('utm_medium');
      const utm_campaign = params.get('utm_campaign');
      return { utm_source, utm_medium, utm_campaign };
    };

    const determineSource = () => {
      const { utm_source, utm_medium, utm_campaign } = getUTMParams();
      if (utm_source) {
        return { utm_source, utm_medium, utm_campaign };
      } else if (document.referrer) {
        try {
          const referrerUrl = new URL(document.referrer);
          return {
            URL: referrerUrl.toString(),
            hash: referrerUrl.hash,
            host: referrerUrl.host,
            hostname: referrerUrl.hostname,
            href: referrerUrl.href,
            origin: referrerUrl.origin,
            password: referrerUrl.password,
            pathname: referrerUrl.pathname,
            port: referrerUrl.port,
            protocol: referrerUrl.protocol,
            search: referrerUrl.search,
            searchParams: Object.fromEntries(referrerUrl.searchParams.entries()),
            username: referrerUrl.username,
          };
        } catch (err) {
          console.error('Error parsing referrer URL:', err);
          return null;
        }
      }
      return null;
    };

    const sourceSentFlag = `sourceSent_${actualUser.id}`;
    if (!localStorage.getItem(sourceSentFlag)) {
      const source = determineSource();
      if (source) {
        sendSourceToBackend(source).then(() => {
          localStorage.setItem(sourceSentFlag, 'true');
        });
      }
      else{
        sendSourceToBackend("").then(() => {
          localStorage.setItem(sourceSentFlag, 'true');
        });
      }
    }
  }, [location.search, actualUser.id]);

  const sendClickCountToBackend = async (userId, clickCount, conversationCount, favoriteCount) => {
    try {
      await api.post('/clicks', { userId, clickCount, conversationCount, favoriteCount });
    } catch (error) {
      console.error('Failed to send click count and conversation count:', error);
      throw error;
    }
  };

  const handleClick = () => {
    const CLICK_THRESHOLD = 20;
    const userId = actualUser.id;

    const currentCount = parseInt(localStorage.getItem('clickCount_' + userId)) || 0;
    const newCount = currentCount + 1;
    localStorage.setItem('clickCount_' + userId, newCount);

    if (newCount >= CLICK_THRESHOLD && !isSendingClicksRef.current) {
      setIsSendingClicks(true);
      isSendingClicksRef.current = true;

      const currentConversationCount = conversationCountRef.current;
      const currentFavoriteCount = favoriteConversations.length;

      sendClickCountToBackend(userId, CLICK_THRESHOLD, currentConversationCount, currentFavoriteCount)
        .then(() => {
          const latestCount = parseInt(localStorage.getItem('clickCount_' + userId)) || 0;
          const updatedCount = latestCount - CLICK_THRESHOLD;
          localStorage.setItem('clickCount_' + userId, updatedCount < 0 ? 0 : updatedCount);
          checkUserLimit();
        })
        .catch((error) => {
          console.error('Error sending click count to backend:', error);
        })
        .finally(() => {
          setIsSendingClicks(false);
          isSendingClicksRef.current = false;
        });
    }
  };

  useEffect(() => {
    const handlePageClick = () => {
      handleClick();
    };

    document.addEventListener('click', handlePageClick);

    return () => {
      document.removeEventListener('click', handlePageClick);
    };
  }, [actualUser.id, favoriteConversations.length]);

  // API Key Modal Handlers
  const openKeyModal = () => {
    setKeyInput(''); // Clear previous input
    setIsKeyModalOpen(true);
  };

  const closeKeyModal = () => {
    setIsKeyModalOpen(false);
    setKeyInput('');
  };

  const handleSaveKey = async () => {
    if (!keyInput.trim()) {
      alert('API key cannot be empty.');
      return;
    }
    const service = selectedService;

    if (actualUser.id === "777777777") {
      // Unauthenticated user: save in localStorage
      const keyName = `${service.toLowerCase()}ApiKey`;
      localStorage.setItem(keyName, keyInput.trim());

      // Update API key status in state and localStorage
      setApiKeyStatus((prevStatus) => ({
        ...prevStatus,
        [serviceKeyMap[service]]: true,
      }));
      localStorage.setItem(`apiKeyStatus_${actualUser.id}`, JSON.stringify({
        ...apiKeyStatus,
        [serviceKeyMap[service]]: true,
      }));

      setHasApiKey(Object.values({ ...apiKeyStatus, [serviceKeyMap[service]]: true }).some(status => status === true));

      setIsKeyModalOpen(false);
      setKeyInput('');
      alert(`${service} API key saved successfully.`);
    } else {
      // Authenticated user: save to backend
      try {
        await api.post('/user/apiKey', { service, apiKey: keyInput.trim() });
        
        // After saving, fetch the updated API key status
        const response = await api.get('/user/apiKeyStatus');
        const apiKeyStatusResponse = response.data; // { openaiApiKey: true, geminiApiKey: false, ... }

        setApiKeyStatus(apiKeyStatusResponse);
        setHasApiKey(Object.values(apiKeyStatusResponse).some(status => status === true));

        // Save to localStorage
        localStorage.setItem(`apiKeyStatus_${actualUser.id}`, JSON.stringify(apiKeyStatusResponse));

        setIsKeyModalOpen(false);
        setKeyInput('');
        alert(`${service} API key saved successfully.`);
      } catch (err) {
        console.error('Error saving API key:', err);
        alert('Failed to save API key.');
      }
    }
  };

  const importSingleFileInputRef = useRef(null);

  //
  // ======================== EXPORT SINGLE CONVERSATION ========================
  //
  const handleExportSingleConversation = async (conversation) => {
    if (!conversation) {
      alert('No conversation selected.');
      return;
    }

    try {
      // Gather all files belonging to the conversation
      const gatherFiles = async (convoId) => {
        const keys = await indexedDBListByPrefix(`conversations/${convoId}/`);
        const files = [];
        for (const key of keys) {
          const record = await indexedDBGet(key);
          const content = record.content;
          // If it's conversation.json, re-encrypt after forcing allowedUsers=[actualUser.id]
          if (key.endsWith('conversation.json')) {
            const decrypted = decryptData(content);
            const convoData = JSON.parse(decrypted);

            // Keep ID intact, add/replace allowedUsers so that
            // the next importer can set it to themselves.
            convoData.allowedUsers = [actualUser.id];

            const newContent = JSON.stringify(convoData, null, 2);
            const reEncrypted = encryptData(newContent);

            files.push({ type: 'file', name: 'conversation.json', content: reEncrypted });
          } else {
            const name = key.split('/').pop();
            files.push({ type: 'file', name, content });
          }
        }

        return {
          type: 'directory',
          name: conversation.name,
          entries: files,
        };
      };

      const conversationDirData = await gatherFiles(conversation.id);
      // Encrypt the entire directory listing
      const jsonString = JSON.stringify(conversationDirData);
      const packageEncrypted = encryptData(jsonString);

      // Create a Blob and download
      const blob = new Blob([packageEncrypted], { type: 'application/octet-stream' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${conversation.name}_conversation.bin`;
      a.click();
      URL.revokeObjectURL(url);

      alert(`Exported conversation "${conversation.name}" successfully!`);
    } catch (err) {
      console.error('Error exporting single conversation:', err);
      alert('Failed to export single conversation.');
    }
  };

  //
  // ======================== IMPORT SINGLE CONVERSATION ========================
  //
  const importSingleConversation = async (data) => {
    try {
      const processDir = async (dataObj, prefix) => {
        for (const entry of dataObj.entries) {
          if (entry.type === 'file') {
            const path = `${prefix}/${entry.name}`;
            await indexedDBPut({ path, content: entry.content });
          } else if (entry.type === 'directory') {
            await processDir(entry, `${prefix}/${entry.name}`);
          }
        }
      };
      // Actually store all files under conversations/{data.id}
      await processDir(data, `conversations/${data.id}`);
    } catch (err) {
      console.error('Error importing single conversation:', err);
      throw err;
    }
  };

  const getUniqueConversationName = async (baseName) => {
    let uniqueName = baseName;
    const convoNames = conversations.map(c => c.name);
    if (convoNames.includes(uniqueName)) {
      let counter = 2;
      while (convoNames.includes(`${baseName}(${counter})`)) {
        counter++;
      }
      uniqueName = `${baseName}(${counter})`;
    }
    return uniqueName;
  };

  const handleImportSingleConversation = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    try {
      const fileContent = await file.text();
      const decrypted = decryptData(fileContent);
      const data = JSON.parse(decrypted);

      // Ensure the data has a name
      if (!data.name) {
        throw new Error('Imported conversation data must include a name.');
      }

      // 1) Check if data.id collides with an existing conversation ID
      const existingConversationIds = conversations.map((c) => c.id);
      let importedId = data.id || uuidv4();
      if (existingConversationIds.includes(importedId)) {
        importedId = uuidv4();
      }
      data.id = importedId;

      // Overwrite the conversation.json inside data.entries
      // so that allowedUsers and ID are correct in the final DB files.
      const conversationJsonEntry = data.entries.find((e) => e.name === 'conversation.json');
      if (conversationJsonEntry) {
        // Decrypt -> parse -> update -> re-encrypt
        const convoDecrypted = decryptData(conversationJsonEntry.content);
        const convoObj = JSON.parse(convoDecrypted);

        // Update ID & allowedUsers so the importer can open it
        convoObj.id = data.id;
        convoObj.allowedUsers = [actualUser.id];
        convoObj.lastModified = Date.now(); // Update lastModified to current time

        // Possibly rename the conversation to avoid name collisions
        const uniqueName = await getUniqueConversationName(data.name);
        convoObj.name = uniqueName;

        // Re-encrypt & store back
        const newConvoContent = JSON.stringify(convoObj, null, 2);
        conversationJsonEntry.content = encryptData(newConvoContent);

        // Also keep data.name in sync with new unique name
        data.name = uniqueName;
      }

      // Import into IndexedDB
      await importSingleConversation(data);

      alert(`Imported conversation successfully as "${data.name}"!`);
      await loadConversations();
    } catch (err) {
      console.error('Error importing single conversation:', err);
      alert('Failed to import single conversation.');
    } finally {
      if (importSingleFileInputRef.current) {
        importSingleFileInputRef.current.value = '';
      }
    }
  };

  // ======================== DELETE FOLDER FUNCTIONALITY ========================

  const handleDeleteFolder = async (folderName) => {
    if (!window.confirm(`Are you sure you want to delete the folder "${folderName}" and all its contents?`)) {
      return;
    }

    try {
      const keys = await indexedDBListByPrefix(`folders/${folderName}/`);
      for (const key of keys) {
        await indexedDBDelete(key);
      }

      // Remove folder from state
      setFolders((prevFolders) => prevFolders.filter(folder => folder.name !== folderName));

      // Remove from expandedFolders and filesInFolders
      setExpandedFolders((prev) => {
        const newExpanded = { ...prev };
        delete newExpanded[folderName];
        return newExpanded;
      });
      setFilesInFolders((prev) => {
        const newFiles = { ...prev };
        delete newFiles[folderName];
        return newFiles;
      });

      alert(`Folder "${folderName}" and all its contents have been deleted successfully.`);
    } catch (err) {
      console.error(`Error deleting folder "${folderName}":`, err);
      alert(`Failed to delete folder "${folderName}".`);
    }
  };

  // ======================== DELETE FILE FUNCTIONALITY ========================

  const handleDeleteFile = async (folderName, fileName) => {
    if (!window.confirm(`Are you sure you want to delete the file "${fileName}" from folder "${folderName}"?`)) {
      return;
    }

    try {
      const sanitizedFileName = sanitizeFileName(fileName);
      const path = `folders/${folderName}/${sanitizedFileName}`;
      await indexedDBDelete(path);

      // Update filesInFolders state
      setFilesInFolders((prev) => {
        const updatedFiles = prev[folderName]?.filter(f => f !== fileName) || [];
        return {
          ...prev,
          [folderName]: updatedFiles,
        };
      });

      // If the folder is 'images', update uploadedImages and imagePathToDataURLRef
      if (folderName === 'images') {
        setUploadedImages((prevImages) => prevImages.filter(img => img.name !== fileName));
        imagePathToDataURLRef.current.delete(`/${folderName}/${fileName}`);
      }

      alert(`File "${fileName}" has been deleted successfully from folder "${folderName}".`);
    } catch (err) {
      console.error(`Error deleting file "${fileName}" from folder "${folderName}":`, err);
      alert(`Failed to delete file "${fileName}" from folder "${folderName}".`);
    }
  };

  const [showMessages, setShowMessages] = useState(false);
  const toggleMessages = () => setShowMessages(prev => !prev);

  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // ===============================================
  // ADD THESE TWO LINES FOR IFRAME FULL-SCREEN STATE:
  // ===============================================
  const [isIframeFullScreen, setIsIframeFullScreen] = useState(false);
  const toggleIframeFullScreen = () => {
    setIsIframeFullScreen(!isIframeFullScreen);
  };

  return (
    <div className="chat-page">
      {/* Limit Reached Bar */}
      {isLimitReached && (
        <div className="limit-reached-bar">
          <span>You have reached the usage limit. Upgrade your account to continue using all features.</span>
          <button onClick={handleUpgrade} className="upgrade-account-btn">
            Upgrade Account
          </button>
        </div>
      )}

      {/* Top Bar with Toggle Button (from code2) */}
      <div className="top-bar">
        {/* Usage Info */}
        <div className="usage-info">
          <p>
            Click Count: {currentClickCount} / {clickLimit}
          </p>
        </div>
        <button
          className={`toggle-sidebar-btn ${isSidebarOpen ? 'open' : 'closed'}`}
          onClick={toggleSidebar}
          aria-label={isSidebarOpen ? 'Hide Sidebar' : 'Show Sidebar'}
        >
          {isSidebarOpen ? '←' : '→'}
        </button>
      </div>

      {/* Sidebar (code2 style + code1 functionality) */}
      <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`}>
        {/* API Key Section */}
        <div className="api-key-section">
          <label htmlFor="service-select">Select Service:</label>
          <select
            id="service-select"
            value={selectedService}
            onChange={(e) => setSelectedService(e.target.value)}
            className="service-select"
            disabled={isLimitReached}
          >
            <option value="" disabled>
              Select a service
            </option>
            {supportedServices.map((service, index) => (
              <option key={index} value={service}>
                {service}
              </option>
            ))}
          </select>

          {supportedServices.map((service, index) => (
            <div key={index} className="service-status">
              <span className="service-name">{service}</span>
              <span
                className={`status-indicator ${
                  apiKeyStatus[serviceKeyMap[service]] ? 'green' : 'red'
                }`}
                title={
                  apiKeyStatus[serviceKeyMap[service]]
                    ? 'API Key Present'
                    : 'API Key Missing'
                }
              ></span>
            </div>
          ))}

          <button
            className="add-key"
            onClick={openKeyModal}
            disabled={isLimitReached || !selectedService}
          >
            {apiKeyStatus[serviceKeyMap[selectedService]]
              ? `Update ${selectedService} Key`
              : `Add ${selectedService} Key`}
          </button>
        </div>

        {/* Conversations Section */}
        <div className="sidebar-section conversations-section">
          {/* Review Button + Modal */}
          <button
            onClick={handleOpenReview}
            className="btn review-btn"
            disabled={isLimitReached}
          >
            Write a Review
          </button>
          <ReviewModal show={reviewModalShow} handleClose={handleCloseReview} displayinputname={actualUser.id === "777777777"}/>

          {/* Help Button + Modal */}
          <button
            onClick={handleOpenHelp}
            className="btn help-btn"
            disabled={isLimitReached}
          >
            Help
          </button>
          <HelpModal show={helpModalShow} handleClose={handleCloseHelp} />

          {/* Share Button + Modal */}
          <button
            onClick={() => setIsShareModalOpen(true)}
            className="btn share-button"
            disabled={isLimitReached}
          >
            Share This App
          </button>
          <ShareModal
            isOpen={isShareModalOpen}
            onRequestClose={() => setIsShareModalOpen(false)}
            shareUrl={shareUrl}
            title={title}
            description={description}
          />

          <button
            onClick={handleCreateConversation}
            className="btn"
            disabled={isLimitReached}
          >
            New Conversation
          </button>

          <button
          className="btn"
          onClick={() => setIsImportDropdownOpen(!isImportDropdownOpen)}
          disabled={isLimitReached}
        >
          Import Single Conversation
        </button>
        {isImportDropdownOpen && (
          <div className="import-dropdown">
            <button
              onClick={() => {
                setIsImportDropdownOpen(false);
                if (importSingleFileInputRef.current) {
                  importSingleFileInputRef.current.click();
                }
              }}
              className="dropdown-option"
              disabled={isLimitReached}
            >
              From Device
            </button>
            <button
              onClick={() => {
                setIsImportDropdownOpen(false);
                setIsImportLinkModalOpen(true);
              }}
              className="dropdown-option"
              disabled={isLimitReached}
            >
              From Link
            </button>
          </div>
        )}

        {/* Hidden File Input for Device Upload */}
        <input
          type="file"
          ref={importSingleFileInputRef}
          style={{ display: 'none' }}
          onChange={handleImportSingleConversation}
          disabled={isLimitReached}
        />

        {/* Import from Link Modal */}
        <Modal
          isOpen={isImportLinkModalOpen}
          onRequestClose={() => setIsImportLinkModalOpen(false)}
          contentLabel="Import from URL"
          className="import-link-modal"
          overlayClassName="import-link-modal-overlay"
          ariaHideApp={false}
        >
          <div className="modal-content">
            <h2>Import Conversation from URL</h2>
            <input
              type="url"
              placeholder="Enter the URL of the conversation file"
              value={importUrl}
              onChange={(e) => setImportUrl(e.target.value)}
              className="import-url-input"
              ref={importLinkInputRef}
            />
            <div className="modal-buttons">
              <button onClick={handleImportFromLink} className="btn import-link-btn">
                Import
              </button>
              <button onClick={() => setIsImportLinkModalOpen(false)} className="btn close-modal-btn">
                Cancel
              </button>
            </div>
          </div>
        </Modal>

          <div className="conversations-header" onClick={toggleConversationsCollapse}>
            <h2>Conversations</h2>
            <button className="collapse-btn">
              {isConversationsCollapsed ? '+' : '-'}
            </button>
          </div>

          {!isConversationsCollapsed && (
            <>
              {conversations.length > 0 ? (
                <>
                  <ul className="conversation-list">
                    {conversations.slice(0, displayedConversations).map((convo, index) => (
                      <li
                        key={convo.id}
                        className={`conversation-item ${
                          currentConversation && currentConversation.id === convo.id
                            ? 'active'
                            : ''
                        } ${
                          isLimitReached && index >= 3
                            ? 'disabled-conversation'
                            : ''
                        }`}
                        onClick={() => handleSelectConversation(convo, index)}
                        style={
                          isLimitReached && index >= 3
                            ? { pointerEvents: 'none', opacity: 0.5 }
                            : {}
                        }
                      >
                        <span>{convo.name}</span>
                        <div className="conversation-actions">
                          {!isLimitReached && (
                            <>
                              <button
                                className="save-favorite-btn"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleFavoriteConversation(convo);
                                }}
                                title={
                                  favoriteConversations.includes(convo.id)
                                    ? 'Remove from Favorites'
                                    : 'Save to Favorites'
                                }
                              >
                                {favoriteConversations.includes(convo.id)
                                  ? '★'
                                  : '☆'}
                              </button>
                              <button
                                className="rename-conversation-btn"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  openRenameConversationModal(convo);
                                }}
                                title="Rename Conversation"
                              >
                                ✏️
                              </button>
                              <button
                                className="delete-conversation-btn"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteConversation(convo);
                                }}
                                title="Delete Conversation"
                              >
                                🗑️
                              </button>
                              <button
                                className="export-conversation-btn"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleExportSingleConversation(convo);
                                }}
                                title="Export This Conversation"
                              >
                                ⬇️
                              </button>
                            </>
                          )}
                        </div>
                      </li>
                    ))}
                  </ul>
                  {displayedConversations < conversations.length && (
                    <button
                      onClick={() =>
                        setDisplayedConversations((prev) => prev + 7)
                      }
                      className="btn load-more-btn"
                    >
                      Load More
                    </button>
                  )}
                </>
              ) : (
                <p>No conversations available.</p>
              )}
            </>
          )}
        </div>

        {/* Favorite Apps Section */}
        <div className="sidebar-section favorite-apps-section">
          <div
            className="favorite-apps-header"
            onClick={toggleFavoriteAppsCollapse}
          >
            <h2>Favorite Apps</h2>
            <button className="collapse-btn">
              {isFavoriteAppsCollapsed ? '+' : '-'}
            </button>
          </div>

          {!isFavoriteAppsCollapsed && (
            <>
              {favoriteConversations.length > 0 ? (
                <ul className="favorite-apps-list">
                  {favoriteConversations
                    .map((favId) => conversations.find((c) => c.id === favId))
                    .filter((convo) => convo !== undefined)
                    .map((convo) => {
                      const idx = conversations.indexOf(convo);
                      return (
                        <li
                          key={convo.id}
                          className={`favorite-conversation-item ${
                            currentConversation &&
                            currentConversation.id === convo.id
                              ? 'active'
                              : ''
                          }`}
                          onClick={() =>
                            handleSelectConversation(convo, idx)
                          }
                          style={
                            isLimitReached && idx >= 3
                              ? { pointerEvents: 'none', opacity: 0.5 }
                              : {}
                          }
                        >
                          {convo.name}
                          {!isLimitReached && (
                            <button
                              className="remove-favorite-btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                removeFavoriteConversation(convo.id);
                              }}
                              title="Remove from Favorites"
                            >
                              ✖️
                            </button>
                          )}
                        </li>
                      );
                    })}
                </ul>
              ) : (
                <p>No favorite apps.</p>
              )}
            </>
          )}
        </div>

        {/* Folders Section */}
        <div className="sidebar-section folders-section">
          <button
            onClick={openCreateFolderModal}
            className="btn"
            disabled={isLimitReached}
          >
            Create New Folder
          </button>

          <div className="folders-header" onClick={toggleFoldersCollapse}>
            <h2>Folders</h2>
            <button className="collapse-btn">
              {isFoldersCollapsed ? '+' : '-'}
            </button>
          </div>

          {!isFoldersCollapsed && (
            <>
              {folders.length > 0 ? (
                <>
                  <ul className="folder-list">
                    {folders.slice(0, displayedFolders).map((folder, index) => (
                      <li key={index} className="folder-item">
                        <div
                          className="folder-header"
                          onClick={() => toggleFolderExpansion(folder.name)}
                        >
                          <span className="folder-name">
                            {folder.name.toLowerCase() === 'images'
                              ? `${folder.name} 📷`
                              : folder.name}
                          </span>
                          <span className="folder-toggle">
                            {expandedFolders[folder.name] ? '-' : '+'}
                          </span>
                        </div>
                        <div className="folder-actions-buttons">
                          <button
                            className="delete-folder-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteFolder(folder.name);
                            }}
                            title="Delete Folder"
                            disabled={isLimitReached}
                          >
                            🗑️
                          </button>
                        </div>
                        {expandedFolders[folder.name] && (
                          <div className="folder-actions">
                            <button
                              onClick={() => {
                                setCurrentUploadFolder(folder.name);
                                if (uploadFileInputRef.current) {
                                  uploadFileInputRef.current.setAttribute(
                                    'data-folder',
                                    folder.name
                                  );
                                  uploadFileInputRef.current.click();
                                }
                              }}
                              className="btn upload-btn"
                              disabled={isLimitReached}
                            >
                              Upload Files
                            </button>
                            <ul className="file-list">
                              {filesInFolders[folder.name] ? (
                                filesInFolders[folder.name].length > 0 ? (
                                  filesInFolders[folder.name].map(
                                    (file, idx) => (
                                      <li key={idx} className="file-item">
                                        <button
                                          onClick={() =>
                                            handleSelectFile(folder.name, file)
                                          }
                                          className="btn file-btn"
                                        >
                                          {file.replace(/_/g, ' ')}
                                        </button>
                                        <button
                                          className="delete-file-btn"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleDeleteFile(folder.name, file);
                                          }}
                                          title="Delete File"
                                          disabled={isLimitReached}
                                        >
                                          🗑️
                                        </button>
                                      </li>
                                    )
                                  )
                                ) : (
                                  <li className="no-files">
                                    No files in this folder.
                                  </li>
                                )
                              ) : (
                                <li className="loading-files">
                                  Loading files...
                                </li>
                              )}
                            </ul>
                          </div>
                        )}
                      </li>
                    ))}
                  </ul>
                  {displayedFolders < folders.length && (
                    <button
                      onClick={() => setDisplayedFolders((prev) => prev + 7)}
                      className="btn load-more-btn"
                    >
                      Load More
                    </button>
                  )}
                </>
              ) : (
                <p>No folders available.</p>
              )}
            </>
          )}
        </div>
      </div>

      {/* Main Chat Area */}
      <div className={`main-chat ${isSidebarOpen ? 'with-sidebar' : 'full-width'}`}>
        {/* 
          IFRAME CONTAINER 
          - ADD a button here to toggle full screen.
        */}
        <div className={`iframe-container ${isIframeFullScreen ? 'full-screen' : ''}`}>
          <iframe
            ref={iframeRef}
            title="Rendered Output"
            className="rendered-iframe"
            srcDoc={indexContent}
            sandbox="allow-scripts allow-same-origin allow-downloads allow-popups allow-modals allow-forms"
          ></iframe>
          {/* NEW BUTTON TOGGLE FULLSCREEN */}
          <button 
            className="toggle-fullscreen-btn"
            onClick={toggleIframeFullScreen}
          >
        {isIframeFullScreen ? <FaTimes size={20} /> : <MdFullscreen size={24} />}
        </button>
        </div>

        {/* Messages Toggle Bar */}
        <div className="messages-toggle-bar" onClick={toggleMessages}>
          {showMessages ? 'Hide Messages' : 'Show Messages'}
        </div>

        {/* Messages Section */}
        <div className={`messages-section ${showMessages ? 'visible' : 'hidden'}`}>
          <div className="messages-container">
            {messages.map((msg, index) => (
              <div key={index} className={`message ${msg.sender}`}>
                <div className="message-content">{msg.content}</div>
              </div>
            ))}
            <div ref={messagesEndRef} />
          </div>
        </div>

        {/* Input Area */}
        <div className="input-area">
          <textarea
            id="prompt-textarea"
            placeholder="Enter your request (e.g., I want a landing page)... Type '/' to insert a file path."
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            onKeyDown={handleTextareaKeyDown}
            disabled={!currentConversation || loading || isLimitReached}
          />
          <div className="buttons">
            <button
              onClick={handleSend}
              disabled={loading || !currentConversation || isLimitReached}
              className="btn send-btn"
            >
              {loading ? 'Generating...' : 'Send'}
            </button>
          </div>
        </div>

        {/* Create Folder Modal */}
        <Modal
          isOpen={isCreateFolderModalOpen}
          onRequestClose={closeCreateFolderModal}
          contentLabel="Create New Folder"
          className="image-modal"
          overlayClassName="image-modal-overlay"
          ariaHideApp={false}
        >
          <div className="modal-content">
            <h2>Create New Folder</h2>
            <input
              type="text"
              placeholder="Enter folder name"
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              className="folder-input"
              disabled={isLimitReached}
            />
            <div className="modal-buttons">
              <button
                onClick={handleCreateFolder}
                className="btn create-folder-btn"
                disabled={isLimitReached}
              >
                Create
              </button>
              <button onClick={closeCreateFolderModal} className="btn close-modal-btn">
                Cancel
              </button>
            </div>
          </div>
        </Modal>

        {/* Rename Conversation Modal */}
        <Modal
          isOpen={isRenameModalOpen}
          onRequestClose={closeRenameConversationModal}
          contentLabel="Rename Conversation"
          className="image-modal"
          overlayClassName="image-modal-overlay"
          ariaHideApp={false}
        >
          <div className="modal-content">
            <h2>Rename Conversation</h2>
            <input
              type="text"
              placeholder="Enter new conversation name"
              value={newConversationName}
              onChange={(e) => setNewConversationName(e.target.value)}
              className="folder-input"
              disabled={isLimitReached}
            />
            <div className="modal-buttons">
              <button
                onClick={handleRenameConversation}
                className="btn rename-conversation-btn"
                disabled={isLimitReached}
              >
                Rename
              </button>
              <button onClick={closeRenameConversationModal} className="btn close-modal-btn">
                Cancel
              </button>
            </div>
          </div>
        </Modal>

        {/* Add/Update API Key Modal */}
        <Modal
          isOpen={isKeyModalOpen}
          onRequestClose={closeKeyModal}
          contentLabel="Add API Key"
          className="key-modal"
          overlayClassName="key-modal-overlay"
          ariaHideApp={false}
        >
          <div className="modal-content">
            <h2>
              {apiKeyStatus[serviceKeyMap[selectedService]]
                ? `Update ${selectedService} API Key`
                : `Add ${selectedService} API Key`}
            </h2>
            <input
              type="text"
              placeholder={`Enter your ${selectedService} API key`}
              value={keyInput}
              onChange={(e) => setKeyInput(e.target.value)}
              className="key-input"
            />
            <div className="modal-buttons">
              <button onClick={handleSaveKey} className="btn save-key-btn">
                Save
              </button>
              <button onClick={closeKeyModal} className="btn close-modal-btn">
                Cancel
              </button>
            </div>
          </div>
        </Modal>

        {/* Hidden File Input for Uploading Files to Folders */}
        <input
          type="file"
          multiple
          ref={uploadFileInputRef}
          style={{ display: 'none' }}
          onChange={handlePerFolderFileUpload}
          disabled={isLimitReached}
        />

        {/* Error Message */}
        {error && (
          <div className="error-message">
            <p>{error}</p>
            {error === 'Request limit reached. Upgrade your account.' && (
              <button onClick={handleUpgrade} className="btn upgrade-btn">
                Upgrade Account
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

// Utility functions for reading and writing files using IndexedDB
const readFile = async (filename, conversationId = null, folderName = null) => {
  const path = (() => {
    if (conversationId) {
      return conversationFilePath(conversationId, filename);
    } else if (folderName) {
      return folderFilePath(folderName, filename);
    } else if (filename === 'favorites.json') {
      return favoritesFilePath();
    } else {
      return filename;
    }
  })();

  try {
    const decryptedContent = await readEncryptedFile(path);
    return decryptedContent;
  } catch (err) {
    console.warn(`${filename} not found in IDB. Returning empty content.`);
    return '';
  }
};

const writeFile = async (filename, content, conversationId = null, folderName = null) => {
  const path = (() => {
    if (conversationId) {
      // Sanitize file name by replacing spaces with underscores
      const sanitizedFileName = sanitizeFileName(filename);
      return conversationFilePath(conversationId, sanitizedFileName);
    } else if (folderName) {
      // Sanitize file name by replacing spaces with underscores
      const sanitizedFileName = sanitizeFileName(filename);
      return folderFilePath(folderName, sanitizedFileName);
    } else if (filename === 'favorites.json') {
      return favoritesFilePath();
    } else {
      return filename;
    }
  })();

  await writeEncryptedFile(path, content);
};

export default ChatPage;
